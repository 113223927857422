import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import CountryFilter from './countryFilter/countryFilter'
import ContinentFilter from './continentFilter/continentFilter'
import BrandsFilter from 'components/shared/brandsFilter/brandsFilter'
import ShippingLabelsTable from './shippingLabelsTable/shippingLabelsTable'
import ShippingLabelsFilters from './shippingLabelsFilters/shippingLabelsFilters'
import DeliveryDatePicker from 'components/shared/deliveryDatePicker/deliveryDatePicker'
import CsvDownloadLink from './csvDownloadLink/csvDownloadLink'
import { fetchData } from 'actions/index'
import { SHIPPING_LABELS } from 'actions/context'
import { getShippingLabels } from 'apis/shippingLabels/graphql.api'
import AppConfig from 'config'

import './shippingLabels.css'

export function ShippingLabels(props) {
  const { data, countriesAllowedToView, status, dispatch } = props

  const getDefaultDeliveryDateRange = () => {
    const today = moment().format('YYYY-MM-DD')
    const endDate = moment().add(1, 'week').format('YYYY-MM-DD')

    return [today, endDate]
  }

  const parsePaginatedResponse = res => res?.edges?.map(({ node }) => node) || []

  const shippingLabels = parsePaginatedResponse(data)
  const allShipperNames = data?.allShipperNames || []

  const totalCount = data?.totalCount || 0
  const { startCursor, endCursor, hasNextPage } = data?.pageInfo || {}

  const filterInitialValues = {
    continent: '',
    country: 'All',
    brands: AppConfig.brands.concat(AppConfig.extraBrands),
    deliveryDateRange: getDefaultDeliveryDateRange(),
  }

  const [filters, setFilters] = useState(filterInitialValues)

  const [currentPage, setCurrentPage] = useState(1)
  const [csvData, setCsvData] = useState([])
  const [cursor, setCursor] = useState()
  const [cursorDirection, setCursorDirection] = useState()

  const areMandatoryFiltersSet = isEmpty(filters.continent) || isEmpty(filters.brands)

  const updateFilterValue = (filterName, filterValue) => {
    if (filterName === 'deliveryDateRange' && filterValue.includes(null)) return

    // reset shipper value as we switch main filters
    if (['continent', 'country'].includes(filterName) && filterValue !== filters[filterName]) {
      // if continent is changed - reset country value back to All
      const newFilters = filterName === 'continent' ? { country: 'All' } : {}

      setFilters(({ shipper: _, ...rest }) => ({
        ...rest,
        [filterName]: filterValue,
        ...newFilters,
      }))
    } else if ('brands' === filterName && filterValue !== filters['brands']) {
      setFilters({ ...filters, ['brands']: filterValue.map(a => a.value) })
    } else {
      setFilters({ ...filters, [filterName]: filterValue })
    }
  }

  const removeFilter = filterName => {
    setFilters(({ [filterName]: _, ...rest }) => rest)
  }

  const fetchShippingLabels = () => {
    if (areMandatoryFiltersSet) return

    const options = {
      ...filters,
      cursor: cursor,
      cursorDirection: cursorDirection,
    }

    dispatch(fetchData(SHIPPING_LABELS, options, []))
  }

  const handleNextPage = () => {
    if (!hasNextPage) return

    setCursor(endCursor)
    setCursorDirection('after')
  }

  const handlePrevPage = () => {
    if (currentPage <= 1) return

    setCursor(startCursor)
    setCursorDirection('before')
  }

  useEffect(() => {
    fetchShippingLabels()
    setCurrentPage(1)

    setCursor()
    setCursorDirection()
  }, [filters])

  useEffect(() => {
    return () => setFilters(filterInitialValues)
  }, [])

  useEffect(() => {
    if (cursor && cursorDirection) fetchShippingLabels()
  }, [cursor, cursorDirection])

  const parseDataForCsv = res => res.body.data?.shippingLabels

  const fetchCsvData = () => {
    const options = { ...filters, perPage: 'all' }

    getShippingLabels(options, []).then(res => {
      const data = parseDataForCsv(res)

      setCsvData(data)
    })
  }

  const filterProps = filterName => ({
    context: SHIPPING_LABELS,
    onChange: value => updateFilterValue(filterName, value),
  })

  return (
    <div className='shipping-labels'>
      <div className='shipping-labels__required-filters'>
        <ContinentFilter
          {...filterProps('continent')}
          continent={filters.continent}
          valuesFromState={false}
        />
        <CountryFilter
          {...filterProps('country')}
          countries={countriesAllowedToView}
          country={filters.country}
          continent={filters.continent}
          valuesFromState={false}
        />
        <BrandsFilter {...filterProps('brands')} includeExtraBrands />
        <DeliveryDatePicker
          {...filterProps('deliveryDateRange')}
          deliveryDateRange={filters.deliveryDateRange}
          valuesFromState={false}
          selectsDateRange
        />
        <button
          className='button-primary refresh-btn'
          disabled={areMandatoryFiltersSet}
          onClick={fetchShippingLabels}
        >
          Refresh
        </button>
      </div>
      {areMandatoryFiltersSet ? (
        'Please select continent and brand to display data'
      ) : (
        <>
          <ShippingLabelsFilters
            shippers={allShipperNames}
            activeFilters={filters}
            onFilterChange={updateFilterValue}
            removeFilter={removeFilter}
          >
            {shippingLabels.length ? (
              <CsvDownloadLink
                {...filters}
                fetchCsvData={fetchCsvData}
                setCsvData={setCsvData}
                csvData={csvData}
              />
            ) : null}
          </ShippingLabelsFilters>
          <ShippingLabelsTable
            status={status}
            data={shippingLabels}
            featureFlags={[]}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalCount={totalCount}
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
          />
        </>
      )}
    </div>
  )
}

ShippingLabels.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  brands: PropTypes.array,
  deliveryDateRange: PropTypes.array,
  status: PropTypes.number.isRequired,
  data: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          shipmentNumber: PropTypes.string.isRequired,
          shipmentDate: PropTypes.string.isRequired,
          deliveryDate: PropTypes.string.isRequired,
          shipper: PropTypes.string.isRequired,
          trackingNumber: PropTypes.string,
          cursor: PropTypes.string,
        }),
      }),
    ),
    pageInfo: PropTypes.shape({
      startCursor: PropTypes.string,
      endCursor: PropTypes.string,
      hasNextPage: PropTypes.bool,
    }),
    totalCount: PropTypes.number,
    allShipperNames: PropTypes.arrayOf(PropTypes.string),
  }),
  countriesAllowedToView: PropTypes.array,
  continent: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    continent: state.shippingLabels.continent,
    country: state.shippingLabels.country,
    deliveryDateRange: state.globalFilters.deliveryDateRange,
    brands: state.globalFilters.brands,
    status: state.shippingLabels.status,
    data: state.shippingLabels.data,
    countriesAllowedToView: state.currentUser.countriesAllowedToView,
  }
}

const connectedShippingLabels = connect(mapStateToProps)(ShippingLabels)

export default connectedShippingLabels
