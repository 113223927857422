import superagent from 'superagent'
import { store } from 'store'
import { setIsLoggedOut } from 'actions/authentication'
import { AUTHENTICATION_TOKEN_REFRESH_REQUESTED } from 'actions/types'
import { openErrorAlert } from 'actions/alert/alert'

const authenticationApi = {
  login: () => {
    const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/login`
    const googleToken = localStorage.getItem('googleToken')

    return superagent.post(url).set('Authorization', googleToken).set('X-Issuer', 'ziplog')
  },

  logout: () => {
    store.dispatch(setIsLoggedOut())
  },

  dispatchAction: action => {
    if (action) {
      store.dispatch(action)
    }
  },

  defaultErrorHandler: (error, failureAction) => {
    if (error?.response?.body?.error) {
      const errorMessage =
        `Error: ${error.response.body.error}\n` +
        'Please contact logistics@marleyspoon.com if you think this is wrong.'

      store.dispatch(openErrorAlert(errorMessage))
    } else {
      const defaultErrorMessage = 'Unexpected Error. Please contact logistics@marleyspoon.com.'
      console.error(error)
      store.dispatch(openErrorAlert(defaultErrorMessage))
    }

    authenticationApi.dispatchAction(failureAction)
  },

  handleForbidden: () => {
    const unauthorizedMessage =
      'Sorry. You do not have the right permissions. ' +
      'Please contact logistics@marleyspoon.com if you think this is wrong.'

    store.dispatch(openErrorAlert(unauthorizedMessage))
  },

  handleUnauthorized: (error, replayAction, failureAction) => {
    const errorMessage = error.response.body.error

    if (/Token is expired/.test(errorMessage)) {
      store.dispatch({
        type: AUTHENTICATION_TOKEN_REFRESH_REQUESTED,
        replayAction,
        failureAction,
      })
    } else {
      authenticationApi.defaultErrorHandler(error, failureAction)
      store.dispatch(setIsLoggedOut())
    }
  },

  handleError: (error, replayAction = null, failureAction = null) => {
    switch (error.status) {
      case 403:
        authenticationApi.handleForbidden()
        break
      case 401:
        authenticationApi.handleUnauthorized(error, replayAction, failureAction)
        break
      default:
        authenticationApi.defaultErrorHandler(error, failureAction)
    }
  },

  getTokenInfo: () => {
    const url = `${process.env.REACT_APP_LOGISTICS_API_URL}/token_info`
    const logisticsToken = localStorage.getItem('logisticsToken')

    return superagent.get(url).set('Authorization', logisticsToken).set('X-Issuer', 'ziplog')
  },
}

export default authenticationApi
