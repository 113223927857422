import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { hideModal } from 'actions/modal'
import { createExceptions } from 'actions/deliveryDateExceptions'
import BaseModal from 'components/modal/baseModal'
import ExceptionsPreviewTable from '../exceptionsPreviewTable/exceptionsPreviewTable'

import './exceptionsSaveModal.css'

export const ExceptionsSaveModal = props => {
  const onSubmit = () => {
    props.dispatch(
      createExceptions(
        props.country,
        props.brands,
        props.orderType,
        props.regularDeliveryDate,
        props.newExceptions,
      ),
    )
    closeModal()
  }

  const closeModal = () => {
    props.dispatch(hideModal())
  }

  return (
    <BaseModal onClose={closeModal}>
      <div className='exceptions-save-modal__title'>
        Are you sure you want to implement these exceptions?
      </div>

      <ExceptionsPreviewTable
        country={props.country}
        exceptions={Object.values(props.newExceptions)}
      />

      <div className='exceptions-save-modal__subtitle'>
        {`By clicking 'yes', all`}
        <span className='exceptions-save-modal__order-type'> {props.orderType.toUpperCase()} </span>
        orders will be delivered on the exceptional slots and dates as specified above.
      </div>

      <button className='exceptions-save-modal__cancel-button' onClick={closeModal}>
        Cancel
      </button>
      <button className='button-primary exceptions-save-modal__submit-button' onClick={onSubmit}>
        {`Yes, I'm sure`}
      </button>
    </BaseModal>
  )
}

ExceptionsSaveModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  country: PropTypes.string,
  brands: PropTypes.array,
  orderType: PropTypes.string,
  regularDeliveryDate: PropTypes.string,
  newExceptions: PropTypes.array,
}

function mapStateToProps(state) {
  return {
    country: state.globalFilters.country,
    brands: state.globalFilters.brands,
    orderType: state.globalFilters.orderType,
    regularDeliveryDate: state.globalFilters.regularDeliveryDate,
    newExceptions: state.deliveryDateExceptions.newExceptions,
  }
}

const connectedExceptionsSaveModal = connect(mapStateToProps)(ExceptionsSaveModal)

export default connectedExceptionsSaveModal
