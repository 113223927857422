import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { addDays, subDays } from 'date-fns'
import { connect } from 'react-redux'
import { saveAs } from 'file-saver'
import { startCase } from 'lodash'
import { fetchData, setGlobalFilters } from 'actions/index'
import { setLoading, setFilters } from 'actions/trackingCsvs.js'
import { trackingApi } from 'apis/trackings.api.js'
import { TRACKING_ACTIVITIES_CSVS, TRACKED_SHIPPERS } from 'actions/context'
import AppConfig from 'config.js'
import './trackingCsvs.css'
import { handleError } from 'apis/shared/handleApiError'

import BrandsFilter from 'components/shared/brandsFilter/brandsFilter'
import CountryFilter from 'components/shared/countryFilter/countryFilter'
import DeliveryDatePicker from 'components/shared/deliveryDatePicker/deliveryDatePicker'
import Filter from 'components/shared/filter/filter'
import Loading from 'components/shared/loading/loading'

export const TrackingCsvs = props => {
  const MIN_DATE_RANGE = 7
  const MAX_DATE_RANGE = 5

  const minDate = subDays(new Date(), MIN_DATE_RANGE)
  const maxDate = addDays(new Date(), MAX_DATE_RANGE)

  const { brands, country, deliveryDateRange, loading, shippers, shipper, status } = props

  useEffect(() => {
    props.dispatch(fetchData(TRACKED_SHIPPERS))
    props.dispatch(setFilters('brands', AppConfig.brands))
    props.dispatch(setFilters('status', 'All'))
    props.dispatch(
      setGlobalFilters({
        deliveryDateRange: [
          moment().format('YYYY-MM-DD'),
          moment().add(1, 'd').format('YYYY-MM-DD'),
        ],
      }),
    )
  }, [])

  const isDisabled = () => {
    return !country
  }

  const handleCsvDownload = () => {
    props.dispatch(setLoading(true))

    if (allBrandsSelected()) {
      return getAllBrandsTrackings()
    }

    getBrandsTrackings()
  }

  const getAllBrandsTrackings = async () => {
    try {
      let params = getParams()
      await getTrackings(params)
    } catch (error) {
      handleError(error, 'There are no results for the given parameters')
    }
    props.dispatch(setLoading(false))
  }

  const getBrandsTrackings = async () => {
    let errors = await Promise.all(
      brands.map(async brand => {
        try {
          let params = getParams(brand)
          await getTrackings(params)
        } catch (error) {
          return { error: error, brand: brand }
        }
      }),
    )

    if (errors.length > 0) {
      let missingResultsBrands = errors.map(error => error.brand)
      handleError(
        errors[0].error,
        `There are no results for the given parameters and brands ${missingResultsBrands}.`,
      )
    }

    props.dispatch(setLoading(false))
  }

  const getTrackings = async params => {
    let response = await trackingApi.getTrackings(params)
    if (response) {
      var filename = getFilename(params)
      saveAsFile(response.body, filename)
    }
  }

  const saveAsFile = (data, filename) => {
    const file = new global.File([data], filename, { type: 'text/plain' })

    saveAs(file)
  }

  const getParams = brand => {
    var [fromDate, toDate] = deliveryDateRange
    return Object.assign(
      {},
      fromDate && { from_date: fromDate },
      toDate && { to_date: toDate },
      country && { country },
      brand && { brand },
      shipper && shipper !== 'All' && { shipper },
      status && status !== 'All' && { status },
    )
  }

  const getFilename = params => {
    const formattedDateRange = `${params['from_date']}_to_${params['to_date']}`
    const formattedShipper = params['shipper']
    const formattedCountry = params['country']?.toUpperCase()
    const formattedBrand = params['brand']?.toUpperCase()
    const formattedStatus = params['status']

    var paramsList = [
      formattedDateRange,
      formattedCountry,
      formattedBrand,
      formattedShipper,
      formattedStatus,
    ].filter(Boolean)

    return `Trackings_${paramsList.join('_')}.csv`
  }

  const handleShipperFilter = e => {
    props.dispatch(setFilters('shipper', e.target.value))
  }

  const handleStatusFilter = e => {
    props.dispatch(setFilters('status', e.target.value))
  }

  const filterByCountryAndBrand = shipper => {
    if (allBrandsSelected()) return shipper.country === country
    return shipper.country === country && brands.includes(shipper.brand)
  }

  const getShippersByCountry = () => {
    return (
      shippers && [...new Set(shippers.filter(s => filterByCountryAndBrand(s)).map(s => s.name))]
    )
  }

  const displayStatuses = option => {
    return startCase(option.replaceAll('_', ' '))
  }

  const allBrandsSelected = () => {
    return brands.length === AppConfig.brands.length
  }

  const shippersByCountry = (country && getShippersByCountry()) || []

  return (
    <Fragment>
      <CountryFilter context={TRACKING_ACTIVITIES_CSVS} countries={props.countriesAllowedToView} />
      <BrandsFilter />
      <DeliveryDatePicker
        context={TRACKING_ACTIVITIES_CSVS}
        minDate={minDate}
        maxDate={maxDate}
        selectsDateRange
      />
      <Filter
        name='shipper'
        data={shippersByCountry}
        onChange={handleShipperFilter}
        labelText='Shipper'
        selectedValue={shipper}
        defaultValue='All'
        defaultText='All Shippers'
      />
      <Filter
        name='status'
        displayName='status'
        customDisplay={displayStatuses}
        onChange={handleStatusFilter}
        data={AppConfig.statuses}
        selectedValue={status}
        defaultValue='All'
        defaultText='All Status'
        labelText='Status'
      />
      <button className='button button-primary' onClick={handleCsvDownload} disabled={isDisabled()}>
        Download
      </button>
      {!country && <div> Please select a country </div>}
      {loading && <Loading />}
    </Fragment>
  )
}

TrackingCsvs.propTypes = {
  dispatch: PropTypes.func.isRequired,
  brands: PropTypes.array,
  country: PropTypes.string,
  deliveryDateRange: PropTypes.array,
  shippers: PropTypes.array,
  shipper: PropTypes.string,
  status: PropTypes.string,
  loading: PropTypes.bool,
  countriesAllowedToView: PropTypes.array,
}

const mapStateToProps = state => {
  return {
    brands: state.globalFilters.brands,
    country: state.globalFilters.country,
    deliveryDateRange: state.globalFilters.deliveryDateRange,
    loading: state.trackingCsvs.loading,
    shipper: state.trackingCsvs.shipper,
    shippers: state.trackingCsvs.shippers,
    status: state.trackingCsvs.status,
    countriesAllowedToView: state.currentUser.countriesAllowedToView,
  }
}

const connectedTrackingCsvs = connect(mapStateToProps)(TrackingCsvs)

export default connectedTrackingCsvs
