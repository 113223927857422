// TODO these methods duplicated in src/apis/graphql.api.js
// As we refactor that api file into more smaller ones,
// we should only use methods from this shared file

import { store } from 'store'
import AppConfig from 'config'

export const getFeatureFlags = () => {
  const flags = store.getState()['featureFlags']
  const flagString = Object.keys(flags)

  if (flagString.length) {
    return flagString.join(',')
  }
}

export const buildCountry = (continent, country, countries) => {
  if (countries) {
    return `[${countries.map(country => country.toUpperCase())}]`
  }

  if (country.toUpperCase() === 'ALL') {
    let countriesByContinent = AppConfig.countries
      .filter(country => country.continent === continent)
      .map(country => country.name.toUpperCase())

    return `[${countriesByContinent}]`
  } else {
    return country.toUpperCase()
  }
}

export const buildBrands = brands => {
  return brands.map(brand => brand.toUpperCase())
}

export const buildDeliveryDate = dates => `["${dates[0]}", "${dates[1]}"]`
