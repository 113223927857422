import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { MultiSelect } from 'react-multi-select-component'

import AppConfig from 'config'
import { clearData, setGlobalFilter } from 'actions/index'
import { upperCaseOptions } from 'helpers/multiSelect'

export const BrandsFilter = props => {
  const brands = props.includeExtraBrands
    ? AppConfig.brands.concat(AppConfig.extraBrands)
    : AppConfig.brands
  const allBrands = upperCaseOptions(brands)
  const [selected, setSelected] = useState(allBrands)
  const overrideStrings = {
    allItemsAreSelected: 'All Brands',
    selectSomeItems: 'Please select',
  }

  useEffect(() => {
    props.dispatch(clearData(props.context))
    props.dispatch(
      setGlobalFilter(
        'brands',
        selected.map(a => a.value),
      ),
    )

    if (props.onChange) props.onChange(selected)
  }, [selected])

  return (
    <div className='multi-select-filter'>
      <label className='filter__label'>Brands</label>
      <MultiSelect
        options={allBrands}
        value={selected}
        disableSearch={true}
        onChange={setSelected}
        labelledBy='Select'
        overrideStrings={overrideStrings}
      />
    </div>
  )
}

function mapStateToProps(state) {
  return {
    brands: state.globalFilters.brands,
  }
}

BrandsFilter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired,
  brands: PropTypes.array,
  brandsFromState: PropTypes.array,
  includeExtraBrands: PropTypes.bool,
  onChange: PropTypes.func,
  valuesFromState: PropTypes.bool,
}

const connectedBrandsFilter = connect(mapStateToProps)(BrandsFilter)
export default connectedBrandsFilter
